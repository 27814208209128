.ticket {
  background-color: white;
  width: 94%;
  margin: 0 3% 5% 3%;
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
}

.ticket h2 {
  margin-bottom: 15px;
}


.ticket-form {
  display: flex;
  flex-direction: column;
}

.ticket-form input, textarea, select {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: none;
  background-color: rgb(254, 254, 254);
}

.success-login {
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(38, 188, 38);
  width: 100%;
  margin-bottom: 5px;
  font-size: larger;
  color: rgb(21, 4, 97);

}

.success-login-link {
  margin-left: 3px;
  color: rgb(207, 207, 241);
}

.success-login h3 {
  font-size: 50px;
}

.date-error {
  color: red;
  font-size: larger;
}

@media screen and (min-width: 768px) {  
  .ticket {
    background-color: white;
    width: 40%;
    margin: 0 30% 5% 30%;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
  }
  
  .ticket h2 {
    margin-bottom: 15px;
  }
  
  
  .ticket-form {
    display: flex;
    flex-direction: column;
  }
  
  .ticket-form input, textarea, select {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    background-color: rgb(255, 255, 255);
  }
}