.my-tickets {
  overflow-wrap: break-word;
}

/* .my-tickets li {
  border: solid rgb(3, 3, 3);
  width: 96%;
  margin: 0 3% 1% 3%;
  list-style: none;
  border-radius: 5px;
  text-align: left;
  display: flex;
  flex-direction: column;
} */

/* .my-tickets li p {
  padding: 2px;
}

.ticket-view {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #7c50ea;
  color: white;
  font-weight: 700;
  font-size: 12px;
} */

.mytickets-filter {
  margin: 10px;
  font-size: 12px;
}

.mytickets-filter input {
  margin: 10px;
  font-size: 20px;
}

.clear-filter {
  background-color:rgb(3, 3, 3);
  padding: 5px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-left: 5px;
}

@media screen and (min-width: 968px) {  
  /* .my-tickets {
    text-align: center;
  }
   */
  /* .my-tickets li {
    border: solid rgb(3, 3, 3);
    width: 40%;
    margin: 0 30% 1% 30%;
    list-style: none;
    border-radius: 5px;
    text-align: left;
  } */
  
  /* .my-tickets li p {
    padding: 5px;
  } */
  
  /* .ticket-view {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: #7c50ea;
    color: white;
    font-weight: 700;
    font-size: 20px;
  } */
  
  .mytickets-filter {
    margin: 10px;
    font-size: 20px;
  }
  
  .mytickets-filter input {
    margin: 10px;
    font-size: 20px;
  }
  
  .clear-filter {
    background-color:rgb(3, 3, 3);
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-left: 5px;
  }
}