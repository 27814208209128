* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

button {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #7c50ea;
  cursor: pointer;
  border-radius: 5px;
}

.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
}

.login .loginContainer {
  border-radius: 10px;
  padding: 5px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.app-title {
  margin: 0 0 70px 0;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.app-title p {
  color: yellow;
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 5px;
  border-radius: 5px
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: red;
}

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}

.home {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
  padding-top: 30px;
}

.navbar {
  background: #8568d0;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 13px;
  flex-direction: column;
}

.navbar h2 {
  color: #fff;
}

.navbar button {
  width: 150px;
  padding: 5px;
  background: #3a2b58;
  margin-top: 10px;
}

.user-info {
  color: rgb(238, 228, 48);
}

.google-sign {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content:space-around;
  cursor: pointer;
}

.google-sign h2 {
  padding: 5px;
}

.avatar {
  display: flex;
  align-items: center;
}

.avatar img {
  margin-left: 15px;
  border-radius: 50%;
}

.nav-link {
  margin-right: 0;
  margin-top: 10px;
  width: 150px;
  padding: 5px;
  background: #3a2b58;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
}

@media screen and (min-width: 768px) {  
  button {
    border: none;
    outline: none;
    width: 100%;
    padding: 15px 0;
    color: #fff;
    font-size: 16px;
    letter-spacing: 1px;
    background: #7c50ea;
    cursor: pointer;
    border-radius: 5px;
  }

  .login {
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    background: #e9e9e9;
    display: flex;
  }

  .login .loginContainer {
    border-radius: 10px;
    padding: 60px;
    margin: auto;
    width: 100%;
    max-width: 520px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: radial-gradient(
      ellipse at left bottom,
      rgba(22, 24, 47, 1) 0%,
      rgba(38, 20, 72, 0.9) 59%,
      rgba(17, 27, 75, 0.9) 100%
    );
    box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
  }

  .login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 19px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 5px;
    border-radius: 5px
  }

  .login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
  }

  .login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: #fff;
  }

  .login .loginContainer .btnContainer p span {
    color: yellow;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
  }

  .login .loginContainer .btnContainer p span:hover {
    color: red;
  }

  .login .loginContainer .errorMsg {
    color: red;
    font-size: 16px;
  }

  .home {
    width: 100%;
    min-height: 100vh;
    background: radial-gradient(
      ellipse at left bottom,
      rgba(22, 24, 47, 1) 0%,
      rgba(38, 20, 72, 0.9) 59%,
      rgba(17, 27, 75, 0.9) 100%
    );
    padding-top: 30px;
  }

  .navbar {
    background: #8568d0;
    width: 100%;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    flex-direction: row;
  }

  .navbar h2 {
    color: #fff;
  }

  .navbar button {
    width: 86px;
    padding: 8px 0;
    background: #3a2b58;
  }

  .user-info {
    color: rgb(238, 228, 48);
  }

  .google-sign {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    justify-content:space-around;
    cursor: pointer;
  }

  .google-sign h2 {
    padding: 10px;
  }

  .avatar {
    display: flex;
    align-items: center;
  }

  .avatar img {
    margin-left: 15px;
    border-radius: 50%;
  }

  .nav-link {
    margin-right: -20%;
    width: 150px;
    padding: 8px;
    background: #3a2b58;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
  }
}